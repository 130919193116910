(function() {
  document.querySelectorAll('[data-assignment] textarea').forEach(function(textarea) {
    let word_count = 0;
    let container = textarea.closest('[data-assignment]')
    let handler = function(){
      container.querySelector('[data-assignment-error]').style.display = 'none';
      let split_words = textarea.value.split(' '); 
      let valid_words = [];
      split_words.forEach(function(word){
        if( word !== '') {
          valid_words.push(word);
        }
      })
      word_count = valid_words.length;
      container.querySelector('[data-word-count]').innerHTML = word_count
      if(word_count < container.dataset.assignmentMinimumWords) {
        container.querySelector('[data-assignment-word-count-not-met]').style.display = 'block';
        container.querySelector('[data-assignment-word-count-met]').style.display = 'none';
      }else {
        container.querySelector('[data-assignment-word-count-not-met]').style.display = 'none';
        container.querySelector('[data-assignment-word-count-met]').style.display = 'block';
      }
    };
    textarea.addEventListener('keyup', handler)
  });
  document.querySelectorAll('[data-assignment-submit]').forEach(function(button) {
    let handler = function() {
      let container = button.closest('[data-assignment]');
      let word_count = parseInt(container.querySelector('[data-word-count]').innerHTML);
      let minimum_words = parseInt(container.dataset.assignmentMinimumWords);
      if(word_count < minimum_words) {
        container.querySelector('[data-assignment-error]').style.display = 'block';
      }else {
        let data = {
          title: container.dataset.assignment,
          submission: container.querySelector('textarea').value
        }
      }
    }
    button.addEventListener('click', handler)
  })
  function buildSuccessMessage(data) {
    let component = `
    <div class="alert alert-success">
      Thanks for your submission
    </div>
    <div>
      <b>Your submission:</b> ${data.submission}
    </div>` 
    return component;
  }
})()