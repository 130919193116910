(function() {
	const navigation_handler = function(event) {
		let trigger = this;
		let nav_direction = trigger.getAttribute('data-lt-quiz-question-navigation');
		let quiz = trigger.closest('[data-lt-quiz]');
		let sections = quiz.querySelectorAll('[data-lt-quiz-question-section]');
		let submit_trigger = quiz.querySelector('[data-lt-quiz-submit]');
		

		let current_section = quiz.querySelector('[data-lt-quiz-question-section][data-lt-quiz-question-visibility="1"]');
		let current_section_index = parseInt(current_section.getAttribute('data-lt-quiz-question-section'));
		
		let new_section_index = 0;
		if (nav_direction  == '+1') {
			//at end
			if (current_section_index == sections.length - 1) {
				new_section_index = 0;
			} else {
				new_section_index = current_section_index + 1;
			}
		} else if (nav_direction  == '-1') {
			//at start
			if (current_section_index == 0) {
				new_section_index = sections.length - 1;
			} else {
				new_section_index = current_section_index - 1;
			}
		}
		
		let new_section = quiz.querySelector(`[data-lt-quiz-question-section="${new_section_index}"]`);
		current_section.style.display = 'none';
		current_section.setAttribute('data-lt-quiz-question-visibility', '0');
		new_section.style.display = 'block';
		new_section.setAttribute('data-lt-quiz-question-visibility', '1');

		if (new_section_index == sections.length - 1) {
			submit_trigger.style.display = 'block';
		} else {
			submit_trigger.style.display = 'none';
		}
	}

	let quiz_control_sections = document.querySelectorAll('[data-lt-quiz-controls]');
	quiz_control_sections.forEach(function(quiz_control_section) {
		let navigation_triggers = quiz_control_section.querySelectorAll('[data-lt-quiz-question-navigation]');
		navigation_triggers.forEach(function(trigger) {
			trigger.addEventListener('click', navigation_handler);
		});
	});
})();