(function(){
	let sections = document.querySelectorAll('[data-conjugator-container]');
	sections.forEach(function(section) {
		let toggle_buttons = section.querySelectorAll('[data-toggle-answers]');
		toggle_buttons.forEach(function(toggle_button) {
			toggle_button.addEventListener('click', toggleAnswersHandler);
		});
		let inputs = section.querySelectorAll('[data-answer-input] input');
		inputs.forEach(function(input) {
			input.addEventListener('keyup', checkAnswer);
		});
	});

  document.querySelectorAll('[data-conjugator-control]').forEach(function(button) {
    let button_type = button.dataset.conjugatorControl
    let container = button.closest('[data-conjugator-container]');
    let word_sets = container.querySelectorAll('[data-conjugator-words]');
    let handler = function() {
      let active = container.querySelector('[data-conjugator-words][style="display: block;"]');
      let active_index = parseInt(active.dataset.conjugatorWords);
      let next_index = 0;
      if (button_type == 'next') {
        if (active_index + 1 < word_sets.length) {
          next_index = active_index + 1
        }

      } else if (button_type == 'previous') {
        if (active_index - 1 < 0) {
          next_index = word_sets.length - 1
        } else {
          next_index = active_index - 1
        } 

      }
      active.style.display = 'none';
      container.querySelector('[data-conjugator-set-count]').innerHTML=next_index + 1
      let next_active = container.querySelector('[data-conjugator-words="' + (next_index) + '"]')
      next_active.style.display = 'block';
    }
    button.addEventListener('click', handler);
  })

	function checkAnswer()
	{
		let icon = this.closest('[data-answer-input]').querySelector('[data-correct-answer]');
		if (this.value.toUpperCase() == this.closest('[data-answer-input]').dataset.answerInput.toUpperCase()) {
			icon.classList.remove('text-white');
			icon.classList.add('text-success');
		} else {
			icon.classList.remove('text-success');
			icon.classList.add('text-white');
		}
	}

  function toggleAnswersHandler()
	{
		let section = this.closest('[data-conjugator-container]');
		let toggle_button = this;
		let input_zones = section.querySelectorAll('[data-answer-input]');
		let answer_zones = section.querySelectorAll('[data-answer]');
    
    if (toggle_button.innerHTML == 'Show Answers') {
      toggle_button.innerHTML = 'Hide Answers';
    } else {
      toggle_button.innerHTML = 'Show Answers';
    }
    input_zones.forEach(function(input_zone){
      if (input_zone.style.display == 'block') {
        input_zone.style.display = 'none';
      } else {
        input_zone.style.display = 'block';
      }
    })
    answer_zones.forEach(function(answer_zone){
      if (answer_zone.style.display == 'block') {
        answer_zone.style.display = 'none';
      } else {
        answer_zone.style.display = 'block';
      }
    })
	}
})();