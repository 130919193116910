(function() {
  // handler to flip the card
  document.querySelectorAll('[data-flash-card]').forEach(function(flash_card) {
    let handler = function() {
      let inner_content = flash_card.querySelector('.flip-card-inner'); 
      if (inner_content.style.transform == 'rotateX(180deg)') {
        inner_content.style.transform = "";
      } else {
        inner_content.style.transform = 'rotateX(180deg)';
      } 
    }
    flash_card.addEventListener('click', handler);
  });
  // handler for the controls
  document.querySelectorAll('[data-flash-card-control]').forEach(function(button) {
    let button_type = button.dataset.flashCardControl
    let container = button.closest('[data-flash-card-container]');
    let flash_cards = container.querySelectorAll('[data-flash-card]');
    let handler = function() {
      let active = container.querySelector('[data-flash-card][style="display: block;"]');
      let active_index = parseInt(active.dataset.flashCard);
      let next_index = 0;
      if (button_type == 'next') {
        if (active_index + 1 < flash_cards.length) {
          next_index = active_index + 1
        }

      } else if (button_type == 'previous') {
        if (active_index - 1 < 0) {
          next_index = flash_cards.length - 1
        } else {
          next_index = active_index - 1
        } 

      }
      active.style.display = 'none';
      container.querySelector('[data-flash-card-count]').innerHTML=next_index + 1
      let next_active = container.querySelector('[data-flash-card="' + (next_index) + '"]')
      next_active.style.display = 'block';
    }
    button.addEventListener('click', handler);
  })
})()