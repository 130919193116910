//listeners for learn_activity component
(function(){
	let sections = document.querySelectorAll('[data-learn-activity]');
		sections.forEach(function(section) {
		let toggle_buttons = section.querySelectorAll('[data-toggle-answers]');
		toggle_buttons.forEach(function(toggle_button) {
			toggle_button.addEventListener('click', toggleTranslationHandler);
		});
		let inputs = section.querySelectorAll('[data-answer-input] input');
		inputs.forEach(function(input) {
			input.addEventListener('keyup', checkAnswer);
		});
	});

	function checkAnswer()
	{
		let icon = this.closest('[data-answer-input]').querySelector('[data-correct-answer]');
		if (this.value.toUpperCase() == this.closest('[data-answer-input]').dataset.answerInput.toUpperCase()) {
			icon.classList.remove('text-white');
			icon.classList.add('text-success');
		} else {
			icon.classList.remove('text-success');
			icon.classList.add('text-white');
		}
	}

	function toggleTranslationHandler()
	{
		let section = this.closest('[data-learn-activity]');
		let toggle_button = this;
		let input_cells = section.querySelectorAll('[data-answer-input]');
		let translate_cells = section.querySelectorAll('[data-answer]');
		translate_cells.forEach(function(cell) {
			if (cell.style.display !== 'none') {
				cell.style.display = 'none';
				toggle_button.innerHTML = 'Hide Answers';
			} else {
				cell.style.display = 'table-cell';
				toggle_button.innerHTML = 'Show Answers';
			}
		});
		input_cells.forEach(function(cell) {
			if (cell.style.display !== 'table-cell') {
				cell.style.display = 'table-cell';
				toggle_button.innerHTML = 'Show Answers';
			} else {
				cell.style.display = 'none';
				toggle_button.innerHTML = 'Hide Answers';
			}
		});	
	}
})();
